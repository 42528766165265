import React, { useEffect, useRef } from "react";
import SaitGrid from "../atomic/SaitGrid";
import SaitFilters from "components/atomic/SaitFilters";
import SaitButton from "../atomic/SaitButton";
import Box from "@mui/material/Box";
import {
	axiosGetForGrid,
	axiosGetMakerForGrid,
	axiosGetMultipleMakerForGrid,
} from "../../utils/AxiosUtils";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setGlobalError } from "../../store/features/globalMessageReducer";
import { EQUAL } from "../../utils/FiltersOperators";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import Typography from "@mui/material/Typography";

export default function FiltersAndGrid(props) {
	const dispatch = useDispatch();
	const module = useSelector((state) => state["module"]["module"]);
	const token = useSelector((state) => state["auth"]["token"]);

	const pageSize = props.gridParams.pageSize || 25;

	const [isFiltering, setIsFiltering] = React.useState(false);
	const [rows, setRows] = React.useState([]);
	const [totalCount, setTotalCount] = React.useState(0);
	const [jsonFilter, setJsonFilter] = React.useState([]);
	const [page, setPage] = React.useState(0);
	const [orderBy, setOrderBy] = React.useState([]);
	const [autoLoadFromProps, setAutoLoadFromProps] = React.useState(true);
	const [lastJsonFilter, setLastJsonFilter] = React.useState([]);
	const [resetFilters, setResetFilters] = React.useState(false);
	const [gridFilters, setGridFilters] = React.useState([]);

	// const [filtersReady, setFiltersReady] = React.useState(false);
	const filtersReady = useRef(false);
	const didMountRef = useRef(false);

	const { t } = useTranslation();

	useEffect(() => {
		if (didMountRef?.["isFiltering"]) {
			if (isFiltering) {
				fetchData();
			}
		} else {
			didMountRef["isFiltering"] = true;
		}
		// eslint-disable-next-line
	}, [isFiltering]);

	useEffect(() => {
		if (didMountRef?.["page"]) {
			setIsFiltering(true);
		} else {
			didMountRef["page"] = true;
		}
	}, [page]);

	useEffect(() => {
		if (didMountRef?.["orderBy"]) {
			handlePageChanged(0);
		} else {
			didMountRef["orderBy"] = true;
		}
		// eslint-disable-next-line
	}, [orderBy]);

	// La coppia [cleanGridFromProps, setCleanGridFromProps] mi permette di comandare lo
	// svuotamento della griglia dall'esterno
	useEffect(() => {
		if (props.cleanGridFromProps) {
			setRows([]);
			setTotalCount(0);
			props.setCleanGridFromProps(false);
		}
		// eslint-disable-next-line
	}, [props.cleanGridFromProps]);

	const fetchData = async () => {
		if (checkRequiredFilters(jsonFilter)) {
			const orderByArray = orderBy.concat(props.gridParams.orderBy);
			const result =
				props.gridParams.fetchData === "axiosGetMakerForGrid"
					? await axiosGetMakerForGrid(
							props.gridParams.findFunction,
							token,
							module,
							dispatch
					  )
					: props.gridParams.fetchData === "axiosGetMultipleMakerForGrid"
					? await axiosGetMultipleMakerForGrid(
							props.gridParams.findFunction,
							token,
							module,
							dispatch,
							jsonFilter,
							orderBy,
							pageSize,
							page
					  )
					: await axiosGetForGrid(
							props.gridParams.findFunction,
							jsonFilter,
							orderByArray,
							pageSize,
							page,
							token,
							module,
							dispatch
					  );
			setRows(result.rows);
			setTotalCount(result.totalCount);
			setIsFiltering(false);
			setLastJsonFilter(jsonFilter);
			props.setExternalRows?.(result.rows);
			props.setControlledFilter?.(false);
		} else {
			setIsFiltering(false);
			props.setControlledFilter?.(false);

			let requiredErrorString = t("insert_all_required_filters");

			if (props.filters.some((filter) => filter.required === true)) {
				requiredErrorString += "\n" + t("required_filters") + ": ";
				props.filters?.forEach((filter) => {
					if (filter.required === true) {
						requiredErrorString += filter.label + ", ";
					}
				});
				requiredErrorString = requiredErrorString.slice(0, -2);
			}

			props.combinedRequiredFilters?.forEach((combinedRequiredFilter) => {
				requiredErrorString += "\n" + t("alternative_filters") + ": ";

				combinedRequiredFilter?.forEach((requiredFilter) => {
					requiredErrorString += requiredFilter.label + ", ";
				});
				requiredErrorString = requiredErrorString.slice(0, -2);
			});

			dispatch(
				setGlobalError({
					module: module,
					errorMessage: requiredErrorString,
				})
			);
		}
	};

	const fetchDataForExcel = async (pageSizeParameter, pageParameter) => {
		if (rows?.length > 0) {
			if (checkRequiredFilters(jsonFilter)) {
				const result = await axiosGetForGrid(
					props.gridParams.findFunction,
					lastJsonFilter,
					orderBy.length > 0 ? orderBy : props.gridParams.orderBy,
					pageSizeParameter,
					pageParameter,
					token,
					module,
					dispatch
				);

				return result;
			} else {
				let requiredErrorString = t("insert_all_required_filters");

				if (props.filters.some((filter) => filter.required === true)) {
					requiredErrorString += "\n" + t("required_filters") + ": ";
					props.filters?.forEach((filter) => {
						if (filter.required === true) {
							requiredErrorString += filter.label + ", ";
						}
					});
					requiredErrorString = requiredErrorString.slice(0, -2);
				}

				props.combinedRequiredFilters?.forEach((combinedRequiredFilter) => {
					requiredErrorString += "\n" + t("alternative_filters") + ": ";

					combinedRequiredFilter?.forEach((requiredFilter) => {
						requiredErrorString += requiredFilter.label + ", ";
					});
					requiredErrorString = requiredErrorString.slice(0, -2);
				});

				dispatch(
					setGlobalError({
						module: module,
						errorMessage: requiredErrorString,
					})
				);
			}
		}
	};

	const checkRequiredFilters = (jsonFilters) => {
		let allRequiredOK = true;

		props.filters?.forEach((filter) => {
			if (filter.required === true) {
				if (Array.isArray(filter.name)) {
					filter.name.forEach((filterCombo) => {
						let comboOperator = filter.operator || EQUAL;
						if (
							!jsonFilters.some(
								(jsonFilter) =>
									jsonFilter.field === filterCombo.storeName &&
									jsonFilter.operator === comboOperator
							)
						) {
							allRequiredOK = false;
							return; // Esco dal ciclo forEach
						}
					});
					if (!allRequiredOK) {
						return; // Esco dal ciclo forEach
					}
				} else {
					if (
						!jsonFilters.some(
							(jsonFilter) =>
								jsonFilter.field === filter.name &&
								jsonFilter.operator === filter.operator
						)
					) {
						allRequiredOK = false;
						return; // Esco dal ciclo forEach
					}
				}
			}
		});

		if (allRequiredOK) {
			props.combinedRequiredFilters?.forEach((combinedRequiredFilter) => {
				let singleCombinedRequiredOK = false;
				combinedRequiredFilter?.forEach((requiredFilter) => {
					if (
						jsonFilters.some(
							(jsonFilter) =>
								jsonFilter.field === requiredFilter.name &&
								jsonFilter.operator === requiredFilter.operator
						)
					) {
						singleCombinedRequiredOK = true;
						return; // Esco dal ciclo forEach combinedRequiredFilter
					}
				});

				if (!singleCombinedRequiredOK) {
					allRequiredOK = false;
					return; // Esco dal ciclo forEach combinedRequiredFilters
				}
			});
		}
		return allRequiredOK;
	};

	const handleFireFilter = async () => {
		handlePageChanged(0);
	};

	const handlePageChanged = async (newPage) => {
		if (newPage !== page) {
			setPage(newPage);
		} else {
			setIsFiltering(true);
		}
	};

	const handleSortChanged = async (newSort) => {
		let newSortArray = [];
		if (newSort[0]) {
			newSortArray.push({
				property: newSort[0].field,
				direction: newSort[0].sort,
			});
		}
		setOrderBy(newSortArray);
	};

	const impostaFiltri = async (newFilters) => {
		filtersReady.current = true;
		setJsonFilter(newFilters);
	};

	useEffect(() => {
		if (autoLoadFromProps && filtersReady.current) {
			if (props.autoload) {
				setIsFiltering(true);
				setAutoLoadFromProps(false);
			} else {
				setAutoLoadFromProps(false);
			}
		}
		// eslint-disable-next-line
	}, [jsonFilter, filtersReady.current]);

	// useEffect(() => {
	// 	if (props.filterListener != null && filtersReady) {
	// 		handleFireFilter();
	// 	}
	// 	// eslint-disable-next-line
	// }, [props.filterListener]);

	useEffect(() => {
		if (props.controlledFilter && filtersReady.current) {
			setIsFiltering(true);
		}
		// eslint-disable-next-line
	}, [props.controlledFilter, filtersReady.current]);

	return (
		<Box
			sx={{
				display: "flex",
				flexGrow: 1,
				flexDirection: "column",
				...props.sx,
			}}
		>
			{props.title && (
				<Typography
					align="center"
					sx={{
						fontWeight: "bold",
						fontSize: 15,
						pb: 1, //padding-bottom
					}}
				>
					{props.title}
				</Typography>
			)}
			<SaitFilters
				filters={props.filters}
				impostaFiltri={impostaFiltri}
				handleFireFilter={handleFireFilter}
				resetFilters={resetFilters}
				setResetFilters={setResetFilters}
				gridFilters={gridFilters}
				setGridFilters={setGridFilters}
				filterButton={
					props.inLineButtonFilter &&
					props.filters?.some((filter) => filter.type != null) ? (
						<>
							<SaitButton
								text={t("filter_grid")}
								loading={isFiltering}
								onClick={handleFireFilter}
								icon={<RefreshIcon />}
								sx={{ color: "secondary.main" }}
								disabled={props.disableFilterButton || false}
							/>
							{props.resetFilter && (
								<SaitButton
									text={t("reset")}
									color="divider"
									loading={isFiltering}
									onClick={() => setResetFilters(true)}
									icon={<FilterAltOffIcon />}
								/>
							)}
						</>
					) : null
				}
			/>
			{!props.inLineButtonFilter && props.filters?.some((filter) => filter.type != null) ? (
				<Box m={1} textAlign="center">
					<SaitButton
						text={t("filter_grid")}
						loading={isFiltering}
						onClick={handleFireFilter}
						endIcon={<RefreshIcon />}
						disabled={props.disabled}
						sx={{ color: "secondary.main" }}
					/>
					{props.resetFilter && (
						<SaitButton
							text={t("reset")}
							color="divider"
							loading={isFiltering}
							onClick={() => setResetFilters(true)}
							endIcon={<FilterAltOffIcon />}
							sx={{ ml: 2 }}
						/>
					)}
				</Box>
			) : null}
			{props.inLineButtonFilter && <Box mt={1} />}

			<SaitGrid
				topButtons={props.topButtons}
				columns={props.gridParams.columns}
				rows={rows}
				isFiltering={isFiltering || autoLoadFromProps}
				pageSize={pageSize}
				handlePageChanged={handlePageChanged}
				page={page}
				rowCount={totalCount}
				handleSortChanged={handleSortChanged}
				selection={props.gridParams.selection}
				setSelectedRecord={props.gridParams.setSelectedRecord}
				isRowSelectable={
					(params) =>
						params.row.statoSvg ? (params.row.statoSvg === "NEW" ? true : false) : true //todo da sistemare
				}
				handleRowDoubleClick={props.handleRowDoubleClick}
				orderBy={props.gridParams.orderBy}
				height={props.height}
				standardToolbar={props.standardToolbar}
				fetchDataForExcel={fetchDataForExcel}
				selectFirstRow={props.selectFirstRow}
				changeGridFilters={setGridFilters}
				gridFilters={gridFilters}
				pivotFunction={props.pivotFunction}
				rowHeight={props.rowHeight}
				paginationMode={props.paginationMode}
				hideFooter={props.hideFooter}
			/>
		</Box>
	);
}
