import React from "react";
import i18next from "../../../localization/i18n";
import InfoIcon from "@mui/icons-material/Info";
import SaitRowAction from "./SaitRowAction";

/*
	Obbligatori:
		id
		onClick
	Facoltativi:
*/

export default function SaitRowActionInfo(config) {
	return SaitRowAction({
		id: config.id,
		icon: <InfoIcon color="primary" />,
		tooltip: i18next.t("information"),
		onClick: config.onClick,
	});
}
