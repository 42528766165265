import React, { useEffect } from "react";
import i18next from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { BUTTON, COMBO, GRID, SIMPLE_GRID, TAB, TEXTFIELD } from "../../../utils/ComponentList";
import { axiosFetchAllCombo, axiosPostMaker } from "../../../utils/AxiosUtils";
import { checkOpen } from "../../../utils/SaitUtils";
import { setGlobalError, setGlobalMessage } from "../../../store/features/globalMessageReducer";
import SaitDialog from "../../../components/atomic/SaitDialog";
import EditPanel from "../../../components/template/EditPanel";
import SaitButton from "../../../components/atomic/SaitButton";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import { activateLoading, deactivateLoading } from "../../../store/features/globalLoadingReducer";
import RefreshIcon from "@mui/icons-material/Refresh";
import SaitRowActionInfo from "./../../../components/atomic/Columns/SaitRowActionInfo";
import SaitRowAction from "./../../../components/atomic/Columns/SaitRowAction";
import InfoIcon from "@mui/icons-material/Info";
import EditWindow from "./../../../components/template/EditWindow";
import { generateRandom } from "./../../../utils/SaitUtils";
import { CONTAINS } from "../../../utils/FiltersOperators";
import QrCodeIcon from "@mui/icons-material/QrCode";
import { setGlobalConfirm } from "../../../store/features/globalConfirmReducer";
import JSZip from "jszip";

export default function NuovoMaker(props) {
	/*************
	 *	DICHIARAZIONI   *
	 **************/
	const dispatch = useDispatch();
	const module = useSelector((state) => state["module"]["module"]);

	/*************
	 *	MODEL   *
	 **************/
	const object = {
		usernameM: "",
		passwordM: "",
		confirmPasswordM: "",
		name: "",
		qrcodePrefix: "",
		idMaker: "",
		norma: "",
		brand: "",
		qty: "",
		idProfile: "",
	};

	const obj = {
		profilo: "",
		count: 0,
		arr: [],
	};

	/*************
	 *	  STATI    *
	 **************/
	const [attemptSave, setAttemptSave] = React.useState(false);
	const [index, setIndex] = React.useState(0);
	const [findMaker, setFindMaker] = React.useState(object);
	const [reloadGrid, setReloadGrid] = React.useState(false);
	const [reloadGridProfili, setReloadGridProfili] = React.useState(false);
	const [openDettaglio, setOpenDettaglio] = React.useState(false);
	const [itemsIwstdnorma, setItemsIwstdnorma] = React.useState([]);
	const [dettaglio, setDettaglio] = React.useState(obj);
	const [page, setPage] = React.useState(0);
	const [selectedRowsMultiselezioneQR, setSelectedRowsMultiselezioneQR] = React.useState([]);

	/*************
	 *	LISTENER   *
	 **************/

	useEffect(() => {
		const comboQueriesFiltered = [
			{
				findFunction: "/norma/findIwstdnorma",
				orderBy: [{ property: "descNormativa", direction: "ASC" }],
				findFilters: [{ idMaker: findMaker.idMaker }],
				setItems: setItemsIwstdnorma,
			},
		];
		axiosFetchAllCombo(comboQueriesFiltered);
		// eslint-disable-next-line
	}, [findMaker.idMaker && index === 2]);

	useEffect(() => {
		generateRandomPassword();
		// eslint-disable-next-line
	}, [props.open]);

	/*************
	 *	  GRID    *
	 **************/
	const fields = [
		{
			title: i18next.t("new"),
			type: TAB,
			items: [
				{
					title: i18next.t("maker"),
					items: [
						{
							label: i18next.t("email"),
							name: "usernameM",
							type: TEXTFIELD,
							required: true,
						},
						{
							label: i18next.t("Credenziali"),
							name: "passwordM",
							password: "password",
							type: TEXTFIELD,
							required: true,
						},
						{
							label: i18next.t("confirm_password"),
							name: "confirmPasswordM",
							password: "password",
							type: TEXTFIELD,
						},
						{
							label: i18next.t("business_name"),
							name: "name",
							type: TEXTFIELD,
							required: true,
						},
						{
							label: i18next.t("barcode_prefix"),
							name: "qrcodePrefix",
							type: TEXTFIELD,
						},
					],
				},
			],
		},
		{
			title: i18next.t("detail") + " Maker",
			type: TAB,
			items: [
				{
					name: "idMaker",
					type: TEXTFIELD,
					label: i18next.t("type_a_maker_id"),
					value: findMaker.idMaker,
					sx2: {
						marginBottom: 2.7,
						marginLeft: "30%",
						marginTop: 3.5,
						maxWidth: 300,
					},
				},
				{
					type: BUTTON,
					label: i18next.t("filter_grid"),
					disabled: !findMaker.idMaker.replace(/^\s+|\s+$/g, ""),
					sx: { margin: 1, marginTop: 3.6, marginRight: "30%", color: "#FFFFFF" },
					onClick: () => {
						setReloadGrid(true);
					},
					endIcon: <RefreshIcon />,
				},
				{
					type: GRID,
					inLineButtonFilter: true,
					controlledFilter: reloadGrid,
					setControlledFilter: setReloadGrid,
					hideFooter: true,
					height: 60,
					gridParams: {
						findFunction: findMaker && "/maker/" + findMaker?.idMaker,
						fetchData: "axiosGetMakerForGrid",
						orderBy: [{ property: "idTemplate", direction: "ASC" }],
						columns: [
							{
								headerName: i18next.t("maker"),
								field: "uid",
								width: 100,
							},
							{
								headerName: i18next.t("username"),
								field: "username",
								flex: 1,
							},
							{
								headerName: i18next.t("business_name"),
								field: "name",
								flex: 1,
							},
							{
								headerName: i18next.t("barcode_prefix"),
								field: "qrcodePrefix",
								width: 150,
							},
						],
					},
					filters: [],
				},
			],
		},
		{
			title: i18next.t("regulation"),
			type: TAB,
			items: [
				{
					title: i18next.t("assignment"),
					items: [
						{
							label: i18next.t("maker"),
							name: "idMaker",
							type: TEXTFIELD,
							required: true,
							defaultValue: findMaker.idMaker,
						},
						{
							label: i18next.t("regulation"),
							name: [{ storeName: "idTipoNorma", comboName: "idTipoNorma" }],
							type: COMBO,
							description: "$(idTipoNorma) - $(titolo)",
							required: true,
							items: itemsIwstdnorma,
							disabled: !findMaker.idMaker.replace(/^\s+|\s+$/g, ""),
							afterChange: (selection) => {
								setFindMaker((prevState) => {
									return {
										...prevState,
										norma: selection.idTipoNorma,
									};
								});
							},
						},
					],
				},
			],
		},
		{
			title: i18next.t("profili"),
			type: TAB,
			height: 850,
			items: [
				{
					title: i18next.t("profile_generator"),
					items: [
						{
							label: i18next.t("Maker"),
							name: "idMaker",
							type: TEXTFIELD,
							defaultValue: findMaker.idMaker,
						},
						{
							label: i18next.t("qty"),
							name: "qty",
							type: TEXTFIELD,
							required: true,
						},
						{
							label: i18next.t("confirm"),
							name: "btProfiles",
							type: BUTTON,
							onClick: () => {
								onSaveProfile();
							},
							required: true,
							sx: { margin: 1, marginTop: 3.6, marginRight: "30%", color: "#FFFFFF" },
						},
					],
				},
			],
		},
		{
			title: i18next.t("detail") + " " + i18next.t("profile"),
			type: TAB,
			items: [
				{
					name: "idMaker",
					type: TEXTFIELD,
					label: i18next.t("type_a_maker_id"),
					value: findMaker.idMaker,
					sx2: {
						marginBottom: 2.7,
						marginLeft: "20%",
						marginTop: 3.5,
						maxWidth: 300,
					},
				},
				{
					name: "idProfile",
					type: TEXTFIELD,
					label: i18next.t("type_a_id_profile"),
					sx2: {
						marginBottom: 2.7,
						marginLeft: "1%",
						marginTop: 3.5,
						maxWidth: 300,
					},
				},
				{
					type: BUTTON,
					label: i18next.t("filter_grid"),
					disabled: !findMaker.idMaker.replace(/^\s+|\s+$/g, ""),
					sx: { margin: 1, marginTop: 3.6, marginRight: "5%", color: "#FFFFFF" },
					onClick: () => {
						setReloadGridProfili(true);
					},
					endIcon: <RefreshIcon />,
				},
				{
					type: GRID,
					inLineButtonFilter: true,
					controlledFilter: reloadGridProfili,
					setControlledFilter: setReloadGridProfili,
					gridParams: {
						findFunction: findMaker && "/norma/getProfile/" + findMaker?.idMaker,
						fetchData: "axiosGetMultipleMakerForGrid",
						selection: {
							multipleSelection: true,
							selectionFunction: (selectedRows) => {
								setSelectedRowsMultiselezioneQR(selectedRows);
							},
						},
						orderBy: [{ property: "idProfile", direction: "ASC" }],
						columns: [
							{
								headerName: i18next.t("profile"),
								field: "idProfile",
								flex: 1,
							},
							{
								headerName: i18next.t("product"),
								field: "prodotto",
								flex: 1,
							},
							{
								headerName: i18next.t("brand"),
								field: "marchio",
								flex: 1,
							},
							{
								headerName: i18next.t("Stato"),
								field: "statoSvg",
								flex: 1,
							},
							SaitRowActionInfo({
								id: "detProfilo",
								icon: <InfoIcon color="success" />,
								tooltip: i18next.t("detail"),
								onClick: (params) => {
									let listaUniqueId = undefined;
									if (params.row.uniqueId)
										listaUniqueId = JSON.parse(params.row.uniqueId);
									if (Array.isArray(listaUniqueId)) {
										listaUniqueId.map((x) => {
											x.id = generateRandom();
											return x;
										});
									} else {
										//Stringa
									}
									const det = {
										profilo: params.row.idProfile,
										count: listaUniqueId?.length || 0,
										arr: listaUniqueId || [],
									};
									setDettaglio(det);
									setOpenDettaglio(true);
								},
							}),
							SaitRowAction({
								id: "generaQr",
								icon: <QrCodeIcon color="success" />,
								tooltip: i18next.t("QR"),
								conditionalRendering: (params) => {
									return params.row.statoSvg === "NEW";
								},
								onClick: (params) => {
									let listaUniqueId = undefined;
									if (params.row.uniqueId) {
										listaUniqueId = JSON.parse(params.row.uniqueId);
										if (Array.isArray(listaUniqueId)) {
											const req = {
												idProfile: params.row.idProfile,
												uniqueIdStart: listaUniqueId[0].UNIQUE_ID,
												uniqueIdEnd:
													listaUniqueId[listaUniqueId.length - 1]
														.UNIQUE_ID,
											};
											dispatch(
												setGlobalConfirm({
													module: module,
													confirmMessage: i18next.t(
														"confirm_message_qr",
														{
															desc: "",
														}
													),
													onConfirm: function () {
														onQRCodeClick(req);
													},
													onCancel: function () {},
												})
											);
										} else {
											//STRINGA
										}
									}
								},
							}),
						],
					},
					filters: [
						{
							field: "idProfile",
							operator: CONTAINS,
							value: findMaker.idProfile,
						},
					],
				},
			],
		},
		{
			title: i18next.t("brand"),
			type: TAB,
			height: 850,
			items: [
				{
					title: i18next.t("brand"),
					items: [
						{
							label: i18next.t("maker"),
							name: "idMaker",
							type: TEXTFIELD,
							required: true,
							defaultValue: findMaker.idMaker,
						},
						{
							label: i18next.t("brand"),
							name: "brand",
							type: TEXTFIELD,
							disabled: !findMaker.idMaker.replace(/^\s+|\s+$/g, ""),
							required: true,
						},
					],
				},
			],
		},
	];

	const fieldsDettaglio = [
		{
			title: "Profilo n. " + dettaglio.profilo,
			items: [
				{
					type: SIMPLE_GRID,
					rows: dettaglio.arr,
					rowCount: dettaglio.count,
					handlePageChanged: true,
					setPage: setPage,
					page: page,
					gridParams: {
						columns: [
							{
								headerName: i18next.t("Object"),
								field: "UNIQUE_ID",
								flex: 1,
							},
							SaitRowAction({
								id: "generaQr",
								icon: <QrCodeIcon color="success" />,
								tooltip: i18next.t("QR"),
								onClick: (params) => {
									if (params.row) {
										const req = {
											idProfile: dettaglio.profilo,
											uniqueIdStart: params.row.UNIQUE_ID,
											uniqueIdEnd: params.row.UNIQUE_ID,
										};
										dispatch(
											setGlobalConfirm({
												module: module,
												confirmMessage: i18next.t("confirm_message_qr", {
													desc: "",
												}),
												onConfirm: function () {
													onQRCodeClick(req);
												},
												onCancel: function () {},
											})
										);
									}
								},
							}),
						],
					},
				},
			],
		},
	];

	/*************
	 *	  FILTERS    *
	 **************/

	/*************
	 *	  BUTTONS    *
	 **************/

	/*************
	 *	FUNCTIONS   *
	 **************/

	const handleOnSave = () => {
		if (findMaker.passwordM === findMaker.confirmPasswordM) {
			setAttemptSave(true);
			dispatch(activateLoading(module));
			const requiredFields = fields[0].items[0].items.filter((e) => {
				if (e.required) {
					return e.name;
				}
				return "";
			});
			let allOk = requiredFields.every((e) => {
				//rimuovo spazi vuoti iniziali e finali
				findMaker[e.name] = findMaker[e.name].replace(/^\s+|\s+$/g, "");
				return findMaker.hasOwnProperty(e.name) && findMaker[e.name] !== "";
			});
			if (!allOk) {
				dispatch(deactivateLoading(module));
				dispatch(
					setGlobalError({
						module: module,
						errorMessage: i18next.t("error_enter_all_required_fields"),
					})
				);
			} else {
				//controllo che lo username sia una mail
				const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
				allOk = regex.test(findMaker["usernameM"]);
				if (allOk) {
					onSave();
				} else {
					dispatch(deactivateLoading(module));
					dispatch(
						setGlobalError({
							module: module,
							errorMessage: i18next.t("enter_an_email_in_the_username_field!"),
						})
					);
				}
			}
		} else {
			dispatch(deactivateLoading(module));
			dispatch(
				setGlobalError({
					module: module,
					errorMessage: i18next.t("wrong_password"),
				})
			);
		}
	};

	const onSave = async () => {
		const req = {
			username: findMaker.usernameM,
			password: findMaker.passwordM,
			name: findMaker.name,
			qrcodePrefix: findMaker.qrcodePrefix.toUpperCase(),
		};
		await axiosPostMaker({
			dispatch: dispatch,
			procedureName: "/maker/",
			parameters: req,
			onSuccess: (response) => {
				setFindMaker((prevState) => {
					return {
						...prevState,
						idMaker: response.data.uid,
					};
				});
				dispatch(deactivateLoading(module));
				setAttemptSave(false);
				props.reload();
				dispatch(
					setGlobalMessage({
						module: module,
						title: i18next.t("creation_completed"),
						message: i18next.t("maker_created_successfully"),
					})
				);
			},
		});
	};

	const onSaveNorma = async () => {
		dispatch(activateLoading(module));
		if (findMaker.norma && findMaker.idMaker.replace(/^\s+|\s+$/g, "")) {
			const req = {
				idMaker: findMaker.idMaker,
				norma: findMaker.norma,
			};
			await axiosPostMaker({
				dispatch: dispatch,
				procedureName: "/norma/insertNorma",
				parameters: req,
				onSuccess: () => {
					dispatch(deactivateLoading(module));
					dispatch(
						setGlobalMessage({
							module: module,
							title: i18next.t("association_completed"),
							message: i18next.t("association_completed_successfully"),
						})
					);
				},
			});
		} else {
			dispatch(deactivateLoading(module));
			dispatch(
				setGlobalError({
					module: module,
					errorMessage: i18next.t("error_enter_all_required_fields"),
				})
			);
		}
	};

	const onSaveBrand = async () => {
		dispatch(activateLoading(module));
		const requiredFields = fields[5].items[0].items.filter((e) => {
			if (e.required) {
				return e.name;
			}
			return "";
		});
		let allOk = requiredFields.every((e) => {
			//rimuovo spazi vuoti iniziali e finali
			findMaker[e.name] = findMaker[e.name].replace(/^\s+|\s+$/g, "");
			return findMaker.hasOwnProperty(e.name) && findMaker[e.name] !== "";
		});
		if (allOk) {
			const req = {
				idMaker: findMaker.idMaker,
				brand: findMaker.brand,
			};
			await axiosPostMaker({
				dispatch: dispatch,
				procedureName: "/norma/insertBrand",
				parameters: req,
				onSuccess: () => {
					dispatch(deactivateLoading(module));
					props.close();
					setFindMaker(object);
					setIndex(0);
				},
			});
		} else {
			dispatch(deactivateLoading(module));
			dispatch(
				setGlobalError({
					module: module,
					errorMessage: i18next.t("error_enter_all_required_fields"),
				})
			);
		}
	};

	const onSaveProfile = async () => {
		const req = {
			idMaker: findMaker.idMaker,
			quantity: findMaker.qty,
		};
		if (findMaker.idMaker && findMaker.qty) {
			dispatch(activateLoading(module));
			await axiosPostMaker({
				dispatch: dispatch,
				procedureName: "/norma/insertProfile",
				parameters: req,
				onSuccess: () => {
					dispatch(deactivateLoading(module));
					dispatch(
						setGlobalMessage({
							module: module,
							title: i18next.t("profile_created"),
							message: i18next.t("profile_created_successfully"),
						})
					);
				},
			});
		} else {
			dispatch(deactivateLoading(module));
			dispatch(
				setGlobalError({
					module: module,
					errorMessage: i18next.t("error_enter_all_required_fields"),
				})
			);
		}
	};

	const onQRCodeClick = async (req) => {
		dispatch(activateLoading(module));
		req.idMaker = findMaker.idMaker;
		await axiosPostMaker({
			dispatch: dispatch,
			procedureName: "/norma/generateQR",
			parameters: req,
			onSuccess: (res) => {
				downloadZip(res.message, req.idProfile);
			},
		});
	};

	const downloadZip = async (svgContent, profilo) => {
		const zip = new JSZip();
		let i = 1;
		svgContent.forEach((x) => {
			zip.file("qrcode_" + profilo + "_" + i + ".svg", x);
			i++;
		});
		const zipBlob = await zip.generateAsync({ type: "blob" });
		const link = document.createElement("a");
		link.href = URL.createObjectURL(zipBlob);
		link.download = "qrcode_" + profilo + ".zip";
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
		dispatch(deactivateLoading(module));
		dispatch(
			setGlobalMessage({
				module: module,
				title: i18next.t("qr_created"),
				message: i18next.t("qr_created_successfully"),
			})
		);
	};

	const onSaveQRMassivi = async () => {
		dispatch(
			setGlobalConfirm({
				module: module,
				confirmMessage: i18next.t("confirm_message_qrs", {
					desc: "",
				}),
				onConfirm: function () {
					selectedRowsMultiselezioneQR.forEach((x) => {
						const listaUniqueId = JSON.parse(x.uniqueId);
						if (Array.isArray(listaUniqueId)) {
							const req = {
								idProfile: x.idProfile,
								uniqueIdStart: listaUniqueId[0].UNIQUE_ID,
								uniqueIdEnd: listaUniqueId[listaUniqueId.length - 1].UNIQUE_ID,
							};
							onQRCodeClick(req);
						}
					});
				},
				onCancel: function () {},
			})
		);
	};

	const generateRandomPassword = () => {
		const caratteri =
			"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()";
		let randomPassword = "";
		for (let i = 0; i < 10; i++) {
			const indiceRandom = Math.floor(Math.random() * caratteri.length);
			randomPassword += caratteri[indiceRandom];
		}
		findMaker.passwordM = randomPassword;
	};

	/*************
	 *	HTML   *
	 **************/

	return (
		<>
			<SaitDialog
				open={checkOpen(props.open)}
				title={i18next.t("new") + " " + i18next.t("maker")}
				width={"lg"}
				scroll={"paper"}
				flexDirection="column"
				content={
					<>
						<EditPanel
							attemptSave={attemptSave}
							stateElement={findMaker}
							setFunction={(e) => setFindMaker(e)}
							fields={fields}
							setTabIndex={(e) => setIndex(e)}
							open={props.open}
						/>
					</>
				}
				actions={
					<>
						<SaitButton
							text={i18next.t("close")}
							color="error"
							endIcon={<CancelIcon />}
							onClick={() => {
								props.close();
								setFindMaker(object);
								setAttemptSave(false);
								setIndex(0);
							}}
						/>
						<div style={{ flexGrow: 1 }} />
						{index !== 1 && index !== 3 && (
							<SaitButton
								text={index === 4 ? "QR MASSIVI" : i18next.t("save")}
								endIcon={<SaveIcon />}
								onClick={
									index === 2
										? onSaveNorma
										: index === 4
										? onSaveQRMassivi
										: index === 5
										? onSaveBrand
										: handleOnSave
								}
								color={"success"}
							/>
						)}
					</>
				}
			/>
			<EditWindow
				close={() => setOpenDettaglio(false)}
				open={openDettaglio}
				fields={fieldsDettaglio}
				width="sm"
			/>
		</>
	);
}
