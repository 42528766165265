export const TEXTFIELD = "TEXTFIELD";
export const DATEFIELD = "DATEFIELD";
export const COMBO = "COMBO";
export const DYNAMICCOMBO = "DYNAMICCOMBO";
export const SWITCH = "SWITCH";
export const TEXTAREA = "TEXTAREA";
export const SEARCHWINDOW = "SEARCHWINDOW";
export const CHECKBOX = "CHECKBOX";
export const GRID = "GRID";
export const BUTTON = "BUTTON";
export const NUMBERFIELD = "NUMBERFIELD";
export const DIVIDER = "DIVIDER";
export const PARAGRAPH = "PARAGRAPH";
export const TAB = "TAB";
export const BUTTON_UPLOAD = "BUTTON_UPLOAD";
export const IMAGE_BOX = "IMAGE_BOX";
export const SIMPLE_GRID = "SIMPLE_GRID";
