import React from "react";
import SaitGrid from "../atomic/SaitGrid";
import Box from "@mui/material/Box";

export default function SimpleGrid(props) {
	const [page, setPage] = React.useState(0);

	const handlePageChanged = (newPage) => {
		if (newPage !== page) {
			props.setPage(newPage);
			setPage(newPage);
		}
	};

	return (
		<Box
			sx={{
				flexGrow: props.height ? null : 1,
				display: "flex",
				...props.sx,
			}}
		>
			<SaitGrid
				{...props}
				rows={props.rows}
				title={props.gridParams.title}
				columns={props.gridParams.columns}
				selection={props.gridParams.selection}
				pageSize={props.pageSize || 100}
				page={page}
				rowCount={props.rows.length}
				standardToolbar={props.standardToolbar}
				hideFooter={props.hideFooter || false}
				handlePageChanged={handlePageChanged}
				paginationMode={"client"}
			/>
		</Box>
	);
}
