import axios from "axios";
import { stringify } from "qs";

const axiosInstance = axios.create({
	baseURL: `${window.Configs.backendAddress}/`,
	paramsSerializer: {
		serialize: (params) => stringify(params, { encode: false }),
	},
});

export default axiosInstance;
